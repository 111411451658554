/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { Languages } from '../../tools/languages';

const SEO = ({ description, lang, meta, title, pathName }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            dutchDescription
            englishDescription
            author
            siteUrl
          }
        }
      }
    `
  );

  const siteUrl = site.siteMetadata.siteUrl;
  const metaTitle = `${title} | ${lang === Languages.EN ? 'EN' : 'NL'}`;
  const metaDescription = description
    ? description
    : lang === Languages.EN
    ? site.siteMetadata.englishDescription
    : site.siteMetadata.dutchDescription;

  if (pathName) {
    var englishAlternate = `${siteUrl}${pathName.replace(`${lang}/`, '')}`;
    var dutchAlternate = `${siteUrl}${pathName}`;
    var defaultAlternate = `${siteUrl}${pathName.replace(`${lang}/`, '')}`;

    if (lang === Languages.EN) {
      englishAlternate = `${siteUrl}${pathName}`;
      dutchAlternate = `${siteUrl}/${Languages.NL}${pathName}`;
      defaultAlternate = `${siteUrl}${pathName}`;
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      link={
        pathName && [
          {
            rel: 'alternate',
            hrefLang: 'nl',
            href: dutchAlternate,
          },
          {
            rel: 'alternate',
            hrefLang: 'en',
            href: englishAlternate,
          },
          {
            rel: 'alternate',
            hrefLang: 'x-default',
            href: defaultAlternate,
          },
          {
            rel: 'canonical',
            href: lang === Languages.EN ? englishAlternate : dutchAlternate,
          },
        ]
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
