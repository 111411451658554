import React from 'react';

import { SecondaryLink } from '../../buttons';

import './index.scss';

import { getSwitchLink, getLocale } from '../../../tools/language.js';

const SwitchLanguage = () => {
  return (
    <SecondaryLink to={getSwitchLink()} id="switch-language" norewrite>
      {getLocale() === 'en' ? 'nl' : 'en'}
    </SecondaryLink>
  );
};

export default SwitchLanguage;
