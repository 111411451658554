import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import SwitchLanguage from './switch-language';
import Link from '../link';
import { SecondaryButton, SecondaryLink } from '../buttons';

import BavakLogo from '../../../static/images/logo.svg';

import { useStaticQuery, graphql } from 'gatsby';

import './index.scss';

const SolutionList = ({ headerId, categorySlug, solutions }) => {
  const isMobile =
    typeof window !== 'undefined' ? window.matchMedia('(max-width: 1000px)') : { matches: false };

  const openSolutionList = (event) => {
    event.preventDefault();
    event.target.parentNode.classList.toggle('selected');
  };

  return (
    <nav className="solution-list">
      <Link
        className="solution-header"
        to={categorySlug === 'support' ? `/support/` : `/solutions/${categorySlug}/`}
        onClick={(event) => {
          if (isMobile.matches) {
            openSolutionList(event, categorySlug);
          }
        }}
      >
        <FormattedMessage id={headerId} />
      </Link>
      <ul>
        {solutions &&
          solutions.map(function (solution, index) {
            return (
              <li key={index}>
                <Link to={`/solutions/${categorySlug}/${solution.slug}/`}>
                  <FormattedMessage id={solution.slug.toLowerCase()} />
                </Link>
              </li>
            );
          })}
      </ul>
    </nav>
  );
};

const Navigation = ({ transparent }) => {
  const data = useStaticQuery(graphql`
    {
      integratedSecurity: allStrapiSolutionCategories(
        filter: { slug: { eq: "integrated-security" } }
      ) {
        nodes {
          solutions {
            slug
          }
          categorySlug: slug
        }
      }
      perimeterSecurity: allStrapiSolutionCategories(
        filter: { slug: { eq: "perimeter-security" } }
      ) {
        nodes {
          solutions {
            slug
          }
          categorySlug: slug
        }
      }
      scanAndDetection: allStrapiSolutionCategories(
        filter: { slug: { eq: "scan-and-detection" } }
      ) {
        nodes {
          solutions {
            slug
          }
          categorySlug: slug
        }
      }
    }
  `);

  const { integratedSecurity, perimeterSecurity, scanAndDetection } = data;

  const [open, setOpen] = useState(false);
  if (typeof window === 'undefined') return null;

  const toggleMenu = () => {
    document.body.classList.toggle('no-scroll', !open);
    setOpen(!open);
  };

  if (!open) document.body.classList.remove('no-scroll');

  return (
    <>
      <Link to="/" id="top-logo">
        <img src={BavakLogo} alt="logo" />
      </Link>
      <nav
        id="main-nav"
        className={'' + (transparent ? ' transparent' : '') + (!open ? ' closed' : '')}
      >
        <SecondaryLink to="/solutions/">
          <FormattedMessage id="solutions" />
        </SecondaryLink>
        <SecondaryLink to="/sectors/">
          <FormattedMessage id="sectors" />
        </SecondaryLink>
        <SecondaryLink to="/support/">
          <FormattedMessage id="support" />
        </SecondaryLink>
        <SecondaryLink to="/about/">
          <FormattedMessage id="about" />
        </SecondaryLink>
        <SecondaryLink to="/news/">
          <FormattedMessage id="news" />
        </SecondaryLink>
        <SecondaryLink to="/careers/">
          <FormattedMessage id="careers" />
        </SecondaryLink>
        <SecondaryButton to="/contact/">
          <FormattedMessage id="contact" />
        </SecondaryButton>
        <a
          href={process.env.BAVAK_PORTAL_URL}
          className="secondary-link"
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="portal" />
        </a>
        <SwitchLanguage />
        <button id="nav-menu-button" onClick={toggleMenu}>
          toggle menu
        </button>
        <div id="main-nav-extra">
          <h2>
            <FormattedMessage id="navigation-header" />
          </h2>
          {integratedSecurity.nodes.length > 0 && (
            <SolutionList
              headerId={integratedSecurity.nodes[0].categorySlug}
              categorySlug={integratedSecurity.nodes[0].categorySlug}
              solutions={integratedSecurity.nodes[0].solutions}
            />
          )}
          {scanAndDetection.nodes.length > 0 && (
            <SolutionList
              headerId={scanAndDetection.nodes[0].categorySlug}
              categorySlug={scanAndDetection.nodes[0].categorySlug}
              solutions={scanAndDetection.nodes[0].solutions}
            />
          )}
          {perimeterSecurity.nodes.length > 0 && (
            <SolutionList
              headerId={perimeterSecurity.nodes[0].categorySlug}
              categorySlug={perimeterSecurity.nodes[0].categorySlug}
              solutions={perimeterSecurity.nodes[0].solutions}
            />
          )}
          <SolutionList headerId="support" categorySlug="support" />
        </div>
      </nav>
    </>
  );
};

Navigation.propTypes = {
  transparent: PropTypes.bool,
};

export default Navigation;
